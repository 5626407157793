import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { addHours, format, isBefore, parseISO, startOfDay } from "date-fns";
import Link from "next/link";

import { jobTypeLookup } from "src/data/job-types";
import { usePermissions } from "src/hooks/usePermissions";
import { useTagsById } from "src/hooks/useTags";
import { Pinned } from "src/icons/pinned";
import { Unpinned } from "src/icons/unpinned";
import type { Job } from "src/services/models/Job";
import { getAddress } from "src/utils/get-address";
import {
  getShortReferenceId,
  jobStatusColor,
  jobStatusToLabel,
  jobTypeColour,
} from "src/utils/jobs";
import { slotMapping } from "src/utils/slot-time";

import { DateAndTimeLabel } from "../../DateAndTimeLabel";
import { StyledChip } from "../../StyledChip";
import { TradepsersonLabel } from "../../tradesperson-label";
import type { AddOrRemovePeopleEventType } from "../JobAssignment";
import { JobAssignment } from "../JobAssignment";
import { useJobFilterStore } from "../JobListFilter/useJobFilters";
import { JobListActionButton } from "../JobListActionButton";
import { useIsCompaniesUIEnabled } from "src/hooks/flags/useCompaniesEnabled";
import { CompanyLabel } from "src/components/CompanyLabel";

interface JobListResultsProps {
  jobs: Job[];
  page: number;
  count: number;
  limit: number;
  onPageChange: (event: unknown, page: number) => void;
  onLimitChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSortOptions: (column: string) => void;
  onTogglePin: (jobId: string, pin: boolean) => void;
  onOpenNotes: (jobId: string, partnerId: string) => void;
  onOpenTags: (jobId: string, companyId: string | undefined | null) => void;
  addOrRemovePeople: (
    jobRefId: string,
    eventType: AddOrRemovePeopleEventType,
    initials: string,
  ) => Promise<string[]>;
}

export const JobListResults = ({
  jobs,
  page,
  count,
  limit,
  onPageChange,
  onLimitChange,
  onSortOptions,
  onTogglePin,
  onOpenNotes,
  onOpenTags,
  addOrRemovePeople,
}: JobListResultsProps) => {
  const theme = useTheme();
  const ordering = useJobFilterStore((state) => state.orderBy);
  const { isRestrictedPartner } = usePermissions();
  const { tagsById } = useTagsById();

  const orderingDirection = ordering && ordering[1] === "DESC" ? "desc" : "asc";

  const sortTooltipText = "Only one column can be sorted at once";
  const companiesEnabled = useIsCompaniesUIEnabled();

  const renderJobStatus = (job: Job) => {
    const jobStatus = job.status;

    return (
      <Stack gap={1}>
        {job.deletedAt && (
          <StyledChip variant="outlined" size="small" label={"CANCELLED"} customcolor={"#B9C0D0"} />
        )}

        <StyledChip
          variant="outlined"
          size="small"
          label={(jobStatusToLabel[jobStatus] ?? "").toUpperCase()}
          customcolor={jobStatusColor[jobStatus]}
        />
      </Stack>
    );
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.grey[50] }}>
              <TableCell>
                <Typography variant="overline">Status</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="overline">Job Info</Typography>
              </TableCell>

              <TableCell>
                <Typography variant="overline">Customer</Typography>
              </TableCell>
              <TableCell>
                <Tooltip enterDelay={300} title={sortTooltipText}>
                  <TableSortLabel
                    active={!!ordering && ordering[0] === "created_at"}
                    direction={orderingDirection}
                    onClick={() => onSortOptions("created_at")}
                  >
                    <Typography variant="overline">Created</Typography>
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              {!isRestrictedPartner && (
                <TableCell>
                  <Typography variant="overline">Tradesperson</Typography>
                </TableCell>
              )}
              {companiesEnabled && (
                <TableCell>
                  <Typography variant="overline">Company</Typography>
                </TableCell>
              )}
              <TableCell>
                <Tooltip enterDelay={300} title={sortTooltipText}>
                  <TableSortLabel
                    active={!!ordering && ordering[0] === "fulfillment_window_start_date_time"}
                    direction={orderingDirection}
                    onClick={() => onSortOptions("fulfillment_window_start_date_time")}
                  >
                    <Typography variant="overline">Booking Date</Typography>
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              {!isRestrictedPartner && (
                <TableCell align="right">
                  <Typography variant="overline">Actions</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <Link
                key={job.id}
                passHref
                legacyBehavior
                href={{ pathname: "/jobs/[id]", query: { id: job.referenceId } }}
              >
                <TableRow
                  hover
                  sx={{
                    cursor: "pointer",
                    backgroundColor: job.pinned ? theme.palette.warning[100] : undefined,
                  }}
                >
                  <TableCell>
                    {
                      <Stack>
                        {renderJobStatus(job)}
                        <Stack mt={"5px"}>
                          {(job.tags || []).map((tagId) => {
                            if (!tagsById[tagId]) {
                              return null;
                            }

                            return (
                              <Typography key={`${job.id}-${tagId}`} variant="subtitle2">
                                # {tagsById[tagId]?.label}
                              </Typography>
                            );
                          })}
                        </Stack>
                      </Stack>
                    }
                  </TableCell>
                  <TableCell>
                    <JobInfo job={job} />
                  </TableCell>
                  <TableCell>
                    <Customer
                      fullName={job.contacts[0].fullName}
                      address={getAddress(job.address)}
                    />
                  </TableCell>
                  <TableCell>
                    <DateAndTimeLabel dateAndTime={job.createdAt} />
                  </TableCell>
                  {!isRestrictedPartner && (
                    <TableCell>
                      {job.tradespersonReferenceId ? (
                        <TradepsersonLabel
                          referenceId={job.tradespersonReferenceId}
                          companyId={job.company?.id}
                        />
                      ) : (
                        <Close sx={{ fontSize: 14 }} color="error" />
                      )}
                    </TableCell>
                  )}
                  {companiesEnabled && (
                    <TableCell>
                      {job.company ? (
                        <CompanyLabel
                          companyId={job.company.id}
                          companyName={job.company.name}
                          source="jobs"
                        />
                      ) : (
                        <Close sx={{ fontSize: 14 }} color="error" />
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <BookingDateTime job={job} />
                  </TableCell>
                  {!isRestrictedPartner && (
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      >
                        {!!job.people && (
                          <JobAssignment
                            jobRefId={job.referenceId}
                            people={job.people}
                            addOrRemovePeople={addOrRemovePeople}
                          />
                        )}
                        <IconButton
                          color={job.pinned ? "warning" : "primary"}
                          onClick={(e) => {
                            e.preventDefault();
                            onTogglePin(job.referenceId, !job.pinned);
                          }}
                        >
                          {job.pinned ? <Pinned /> : <Unpinned />}
                        </IconButton>

                        <JobListActionButton
                          jobId={job.id}
                          onOpenNotes={() => {
                            onOpenNotes(job.id, job.partnerId);
                          }}
                          onOpenTags={() => {
                            onOpenTags(job.id, job.companyId);
                          }}
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        showFirstButton
        onPageChange={onPageChange}
        onRowsPerPageChange={onLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
};

function JobInfo({ job }: { job: Job }) {
  return (
    <>
      <Typography>{job.brand}</Typography>
      <Typography color={jobTypeColour[job.jobType]} variant="h5" fontWeight="600">
        {jobTypeLookup.get(job.jobType)}
      </Typography>
      <Typography variant="subtitle2">{getShortReferenceId(job.referenceId)}</Typography>
    </>
  );
}

interface CustomerProps {
  fullName: string;
  address: string;
}

const Customer = ({ fullName, address }: CustomerProps) => {
  return (
    <Stack>
      <Typography variant="h5" fontWeight="600">
        {fullName}
      </Typography>
      <Typography variant="body2" color="neutral.500">
        {address}
      </Typography>
    </Stack>
  );
};

function BookingDateTime({ job }: { job: Job }) {
  const date_str = job.fulfillmentWindow?.startDateTime;

  if (!date_str) return null;

  const date = parseISO(date_str);
  if (!date_str) return null;

  let slot: JSX.Element;

  if (isBefore(date, addHours(startOfDay(date), slotMapping["12-16"]))) {
    slot = <Typography>☀️ Morning</Typography>;
  } else if (isBefore(date, addHours(startOfDay(date), slotMapping["16-20"]))) {
    slot = <Typography>🫖 Afternoon</Typography>;
  } else {
    slot = <Typography>🌘 Evening</Typography>;
  }

  return (
    <Stack>
      <Typography fontWeight="600" component="time">
        {format(date, "d/MM/yyyy")}
      </Typography>
      {slot}
    </Stack>
  );
}
