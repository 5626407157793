import { useJobs } from "src/services/queries";

export const useFailedToAutoAllocateQFCount = (): number =>
  useJobs({ variables: { pageSize: 0, statuses: ["failed_to_auto_allocate"] } }).data?.count ?? 0;

export const useNoAccessQFCount = (): number =>
  useJobs({ variables: { pageSize: 0, statuses: ["no_access"] } }).data?.count ?? 0;

export const useJobsheetUncompletedQFCount = (): number =>
  useJobs({ variables: { pageSize: 0, jobsheetUncompletedQuickFilter: true } }).data?.count ?? 0;

export const useRequiresJobsheetApprovalQFCount = (): number =>
  useJobs({ variables: { pageSize: 0, requiresJobsheetApproval: true } }).data?.count ?? 0;
