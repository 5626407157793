import Tune from "@mui/icons-material/Tune";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import type { ChangeEvent } from "react";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";

import { DateRangeFilter } from "src/components/date-range-filter/DateRangeFilter";
import { QuickFilterCard } from "src/components/QuickFilterCard";
import { SearchBar } from "src/components/search-bar/search-bar";
import { useIsBulkImportJobsEnabled } from "src/hooks/flags/useIsBulkImportJobsEnabled";
import { usePermissions } from "src/hooks/usePermissions";

import type { DateRangeFilterType } from "../JobListFilter/useJobFilters";
import { useJobFilterDispatch, useJobFilterStore } from "../JobListFilter/useJobFilters";
import { Filters } from "../JobListFilters/JobListFilters";
import { useTheme } from "@mui/material";
import { AddJobButton } from "./AddJobButton";

interface JobListToolbarProps {
  initiateFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  isAdmin: boolean;
  failedToAutoAllocateQFCount: number;
  noAccessQFCount: number;
  jobsheetUncompletedQFCount: number;
  requiresJobsheetApprovalQFCount: number;
}

export const JobListToolbar = (props: JobListToolbarProps) => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const handleToggleFilter = () => setFilterOpen((prev) => !prev);
  const dispatch = useJobFilterDispatch();
  const searchTerm = useJobFilterStore((state) => state.searchTerm ?? "");
  const dateRange = useJobFilterStore((state) => state.dateRange ?? {});
  const filterOptionsCount = useJobFilterStore((state) => {
    return state.jobStatuses.length + state.partnerIds.length + (state.tagIds.length || 0);
  });

  const selectedQuickFilterCard = useJobFilterStore((state) => state.quickFilter);

  const handleUpdateSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        dispatch({ kind: "search", term: event.target.value });
      } else {
        dispatch({ kind: "clearSearch" });
      }
    },
    [dispatch],
  );

  const updateDateRange = useCallback(
    (dateRange: DateRangeFilterType) => {
      dispatch({ kind: "dateRange", dateRange });
    },
    [dispatch],
  );

  const { group } = usePermissions();
  const canAddJob = group !== "restricted_partner";
  const reducedContent = !canAddJob;

  const isBulkImportJobsEnabled = useIsBulkImportJobsEnabled();

  return (
    <Stack>
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" padding={1}>
        <Typography variant="h3">Jobs</Typography>

        <Stack direction="row" gap={2} alignItems="center">
          {canAddJob && (
            <AddJobButton
              initiateFileUpload={props.initiateFileUpload}
              isUploading={props.isUploading}
              canBulkUpload={props.isAdmin && isBulkImportJobsEnabled}
            />
          )}
          <DateRangeFilter dateRange={dateRange} updateDateRange={updateDateRange} />
          <SearchBar value={searchTerm} onUpdateSearch={handleUpdateSearch} name="jobs" />

          {!reducedContent && (
            <Badge badgeContent={filterOptionsCount} color="primary">
              <IconButton
                onClick={handleToggleFilter}
                aria-label="Show Filter"
                color={filterOptionsCount > 0 ? "primary" : undefined}
              >
                <Tune />
              </IconButton>
            </Badge>
          )}
        </Stack>
      </Stack>

      {!reducedContent && (
        <Stack
          sx={{
            backgroundColor: theme.palette.grey[100],
            borderTop: theme.palette.grey[200],
            padding: 2,
            gap: 1,
          }}
        >
          <Filters isOpen={filterOpen} />

          <Stack direction="row" gap={2}>
            <QuickFilterCard
              header="All Jobs"
              subheader="Unfiltered List"
              onClick={() => dispatch({ kind: "selectQuickFilter", filter: "unfiltered_list" })}
              isSelected={selectedQuickFilterCard === "unfiltered_list"}
            />
            <QuickFilterCard
              subheader="Failed To Auto Allocate"
              header={props.failedToAutoAllocateQFCount}
              onClick={() =>
                dispatch({ kind: "selectQuickFilter", filter: "failed_to_auto_allocate" })
              }
              isSelected={selectedQuickFilterCard === "failed_to_auto_allocate"}
            />
            <QuickFilterCard
              subheader="No Access"
              header={props.noAccessQFCount}
              onClick={() => dispatch({ kind: "selectQuickFilter", filter: "no_access" })}
              isSelected={selectedQuickFilterCard === "no_access"}
            />
            <QuickFilterCard
              subheader="Jobsheet Incomplete"
              header={props.jobsheetUncompletedQFCount}
              onClick={() =>
                dispatch({ kind: "selectQuickFilter", filter: "jobsheet_uncompleted" })
              }
              isSelected={selectedQuickFilterCard === "jobsheet_uncompleted"}
            />
            <QuickFilterCard
              subheader="Requires Approval"
              header={props.requiresJobsheetApprovalQFCount}
              onClick={() =>
                dispatch({ kind: "selectQuickFilter", filter: "requires_jobsheet_approval" })
              }
              isSelected={selectedQuickFilterCard === "requires_jobsheet_approval"}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
