import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import type { SxProps } from "@mui/system";
import React, { useMemo } from "react";

import { useAuthStore } from "src/context/authStore";
import { useTagsById } from "src/hooks/useTags";
import { usePartners } from "src/services/queries";
import { typeCast } from "src/utils/assert";
import { isNotNullish } from "src/utils/isNotNullish";

import type { JobStatusOption } from "../JobListFilter/useJobFilters";
import {
  JobStatusFilters,
  useJobFilterDispatch,
  useJobFilterStore,
} from "../JobListFilter/useJobFilters";

interface FiltersProps {
  isOpen: boolean;
}

export function Filters({ isOpen }: FiltersProps): JSX.Element {
  const partnerFiltersEnabled = useAuthStore((auth) => auth.isAdmin);
  return (
    <Collapse in={isOpen}>
      <Stack flexDirection="row" flexWrap="wrap">
        <JobStatusFilter />
        {partnerFiltersEnabled && <PartnerFilter />}
        <JogTagFilters />
      </Stack>
    </Collapse>
  );
}

function JobStatusFilter(): JSX.Element {
  const dispatch = useJobFilterDispatch();
  const selectedJobStatuses = useJobFilterStore((state) => state.jobStatuses);

  const jobStatusOptions = Object.entries(JobStatusFilters).map(([value, label]) => {
    typeCast<JobStatusOption>(value);
    return { value, label };
  });

  const handleToggleSelectAll = () => {
    if (selectedJobStatuses.length !== jobStatusOptions.length) {
      dispatch({
        kind: "selectStatus",
        status: jobStatusOptions.map(({ value }) => value),
      });
    } else {
      dispatch({ kind: "clearStatus" });
    }
  };

  return (
    <FormGroup sx={formGroupStyles}>
      <Typography variant="overline" sx={{ gridArea: "Header" }}>
        Job Status
      </Typography>

      <FormControlLabel
        sx={{ margin: 0 }}
        control={
          <Checkbox
            size="small"
            onChange={handleToggleSelectAll}
            checked={selectedJobStatuses.length === jobStatusOptions.length}
          />
        }
        label="Select All"
      />

      {jobStatusOptions.map(({ value, label }) => {
        return (
          <FormControlLabel
            sx={{ margin: 0 }}
            key={value}
            control={
              <Checkbox
                size="small"
                onChange={() => dispatch({ kind: "toggleStatus", status: value })}
                value={value}
                checked={selectedJobStatuses.includes(value)}
              />
            }
            label={label}
          />
        );
      })}
    </FormGroup>
  );
}

function PartnerFilter(): JSX.Element {
  const dispatch = useJobFilterDispatch();
  const selectedPartnerIds = useJobFilterStore((state) => state.partnerIds);

  const partnersQuery = usePartners({ variables: {} });

  const handleToggleSelectAll = () => {
    if (selectedPartnerIds.length !== partnersQuery.data?.length) {
      dispatch({
        kind: "selectPartnerIds",
        partnerIds: partnersQuery.data?.map((partner) => partner.id) ?? [],
      });
    } else {
      dispatch({ kind: "clearPartnerIds" });
    }
  };

  return (
    <FormGroup sx={formGroupStyles}>
      <Typography variant="overline" sx={{ gridArea: "Header" }}>
        Partner
      </Typography>

      <FormControlLabel
        sx={{ margin: 0 }}
        control={
          <Checkbox
            size="small"
            onChange={handleToggleSelectAll}
            checked={selectedPartnerIds.length === partnersQuery.data?.length}
          />
        }
        label="Select All"
      />

      {partnersQuery.data?.map((partner) => {
        return (
          <FormControlLabel
            sx={{ margin: 0 }}
            key={partner.id}
            control={
              <Checkbox
                size="small"
                onChange={() => dispatch({ kind: "togglePartnerId", partnerId: partner.id })}
                value={partner.id}
                checked={selectedPartnerIds.includes(partner.id)}
              />
            }
            label={partner.name}
          />
        );
      })}
    </FormGroup>
  );
}

function JogTagFilters(): JSX.Element {
  const { tagsById } = useTagsById();

  const dispatch = useJobFilterDispatch();
  const selectedTagIds = useJobFilterStore((state) => state.tagIds);
  const orderedTags = useMemo(() => {
    const tags = Object.values(tagsById)
      .filter(isNotNullish)
      .sort((a, b) => b.count - a.count);
    return tags;
  }, [tagsById]);

  return (
    <>
      <FormGroup sx={formGroupStyles}>
        <Typography variant="overline" sx={{ gridArea: "Header" }}>
          Tags
        </Typography>

        {orderedTags.slice(0, 5)?.map((tag) => {
          return (
            <FormControlLabel
              sx={{ margin: 0 }}
              key={tag.id}
              control={
                <Checkbox
                  size="small"
                  onChange={() => dispatch({ kind: "toggleTagId", tagId: tag.id })}
                  value={tag.id}
                  checked={selectedTagIds.includes(tag.id)}
                />
              }
              label={tag.label}
            />
          );
        })}

        <Autocomplete
          multiple
          size="small"
          sx={{ minWidth: "250px", maxWidth: "250px", mt: "10px", ml: "10px" }}
          options={orderedTags.slice(5, orderedTags.length)}
          renderInput={(params) => <TextField {...params} placeholder="Select other tags" />}
          disableCloseOnSelect
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox checked={selectedTagIds.includes(option.id)} />
              {option.label}
            </li>
          )}
          onChange={(_e, _value, _reason, option) => {
            if (option) {
              dispatch({ kind: "toggleTagId", tagId: option.option.id });
            }
          }}
          clearIcon={false}
        />
      </FormGroup>
    </>
  );
}

export const formGroupStyles: SxProps = {
  flex: 1,
  display: "grid",
  gridTemplateAreas: `"Header" "option1"`,
  gridAutoRows: "min-content",
  gridAutoColumns: "max-content",
};
