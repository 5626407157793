import { useMemo } from "react";
import { useDebounce } from "use-debounce";

import { useJobFilterStore } from "src/components/job/JobListFilter/useJobFilters";
import type { GetJobsInput } from "src/services/models/inputs";
import { useJobs } from "src/services/queries";

export function useJobsQuery() {
  const filter = useJobFilterStore();

  const ordering = useMemo(() => {
    // Ordering always first by pinned jobs
    const ordering = ["-pinned"];

    if (filter.orderBy) {
      ordering.push(`${filter.orderBy[1] == "DESC" ? "-" : ""}${filter.orderBy[0]}`);
    }

    return ordering;
  }, [filter.orderBy]);

  const cleanedUpFilters = useMemo(() => {
    const statuses = new Set(filter.jobStatuses);
    let include_deleted: boolean | null = null;
    let jobsheetUncompleted: boolean | null = null;
    let requiresJobsheetApproval: boolean | null = null;

    if (statuses.has("include_deleted")) {
      include_deleted = true;
      statuses.delete("include_deleted");
    }

    if (statuses.has("flagged_for_jobsheet_approval")) {
      requiresJobsheetApproval = true;
      statuses.delete("flagged_for_jobsheet_approval");
    }

    switch (filter.quickFilter) {
      case "unfiltered_list":
        break;
      case "failed_to_auto_allocate":
        statuses.add("failed_to_auto_allocate");
        break;
      case "no_access":
        statuses.add("no_access");
        break;
      case "jobsheet_uncompleted":
        jobsheetUncompleted = true;
        break;
      case "requires_jobsheet_approval":
        requiresJobsheetApproval = true;
        break;

      default:
        const _shouldNeverHappen: never = filter.quickFilter;
    }

    return {
      statuses: [...statuses],
      include_deleted,
      jobsheetUncompleted,
      requiresJobsheetApproval,
    };
  }, [filter.jobStatuses, filter.quickFilter]);

  const [variables] = useDebounce(
    useMemo(
      () =>
        ({
          page: filter.page + 1,
          pageSize: filter.limit,
          search: filter.searchTerm || null,
          ordering: ordering,
          includeDeleted: cleanedUpFilters.include_deleted,
          jobsheetUncompletedQuickFilter: cleanedUpFilters.jobsheetUncompleted || null,
          partnerIdIn: filter.partnerIds ?? null,
          statuses: cleanedUpFilters.statuses,
          fulfillmentWindowStartDateTimeAfter: filter.dateRange.startDate ?? null,
          fulfillmentWindowStartDateTimeBefore: filter.dateRange.endDate ?? null,
          tagIds: filter.tagIds ?? null,
          requiresJobsheetApproval: cleanedUpFilters.requiresJobsheetApproval,
        }) satisfies GetJobsInput,
      [
        cleanedUpFilters.requiresJobsheetApproval,
        cleanedUpFilters.include_deleted,
        cleanedUpFilters.jobsheetUncompleted,
        cleanedUpFilters.statuses,
        filter.limit,
        filter.page,
        filter.partnerIds,
        filter.searchTerm,
        ordering,
        filter.dateRange.startDate,
        filter.dateRange.endDate,
        filter.tagIds,
      ],
    ),
    250,
  );

  const jobsQuery = useJobs({
    variables,
    keepPreviousData: true,
  });
  return jobsQuery;
}
